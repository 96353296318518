import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import WhatWeDoNav from '../../components/WhatWeDoNav'
import Layout from '../../components/Layout'

const LogoRow = styled(Row)`
  font-size: 72px; margin-bottom: 20px;
  @media(max-width: 425px) {
    font-size: 45px;
  }
`

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="what-we-do">
        <Helmet
          title="Custom Software Development | Bishop Software"
          meta={[
            { name: 'description', content: 'We are experts at developing cross-platform applications and integrating real-time and traditional systems. Let\'s talk.' },
            { name: 'keywords', content: 'software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #2c3532 0%, #568c6c 73%, #78c498 90%, #edf5e0 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#7ac99a 0px, #389583 100%)' section="what-we-do" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: '#FFCB9B', fontSize: '40px', fontWeight: 300}}>Custom Software Development</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>Improving productivity for your teams with modern PC, mobile, web, and database applications.</h3>
              <div style={{marginTop: '35px', paddingBottom: '35px'}}>
                <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
              </div>
            </div>
          </Container>
        </section>
      <Scroll.Element name="contact" />

        <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <Container>
        <Row>
          <Col md="8" xs="12">
            <p style={{color: '#555', fontSize: '22px'}}>
            We can help with your product or application development needs. Our services include application development for web, desktop, embedded, and mobile, and development of developer tools (SDK, API, Drivers, etc.).
            </p>
            <p style={{color: '#555', fontSize: '18px'}}>
            Bishop Software has experience developing apps for PCs on a variety of software platforms. We have built PC-based software systems using Microsoft, Oracle, PostgreSQL, and MongoDB databases, web services, hardware drivers, and more. We have integrated application components using protocols such as TCP/IP, serial, object-relational mappings, direct SQL queries, and shared memory manipulation.
            </p>
          </Col>
          <Col md="4" xs="12" className="d-none d-md-block">

            <WhatWeDoNav title="Related Services" titleBackground="#dbe5d0" titleColor="#333" color="#288D91" links={[
              { link: '/web-mobile-development', title: 'Web & Mobile Development'},
              { link: '/software-consulting', title: 'Software Consulting'},
              { link: '/user-experience', title: 'User Experience & Design'}
            ]} />

          </Col>
        </Row>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container className="text-center">
        <h2 style={{color: '#fff'}}>PC Application Development Services</h2>
        <p>We not only design new applications, but we also have the ability to replace legacy applications with modern applications, integrate new applications with existing data stores, and integrate managed and unmanaged code. We are experts at developing cross-platform applications and integrating real-time and traditional systems. Integration with embedded devices and networked components is another area of expertise for our developers.</p>

        <p>If your application requires a database, Bishopsoft engineers have extensive experience with database design and SQL, including T-SQL and PL/SQL, and have experience with the Entity Framework and LINQ. We can also design custom protocols for communication over any interface. Using MVC/MVVM design patterns, we utilize separation of concerns (SoC) when designing systems.</p>
        <div style={{marginTop: '40px'}}>
          <Link to="/contact" className="btn btn-primary round">Work With Us</Link>
        </div>
      </Container>
    </section>

    <section style={{textAlign: 'center', padding: '70px 0', backgroundImage: 'linear-gradient(132deg, rgba(3,57,115,1) 0%, rgba(44,136,148,1) 73%, rgba(26,174,196,1) 90%, rgba(33,225,255,1) 100%)', color: '#fafafa'}}>
      <Container>
        <LogoRow>
          <Col md="3">
            <i className="fab fa-windows" style={{color: '#ffc107'}} />
          </Col>
          <Col md="3">
            <i className="fab fa-node-js" style={{color: '#ffc107'}} />
          </Col>
          <Col md="3">
            <i className="icon-java" style={{color: '#ffc107'}} />
          </Col>
          <Col md="3">
            <i className="icon-go" style={{color: '#ffc107'}} />
          </Col>
        </LogoRow>
        <Row style={{marginBottom: '15px'}}>
          <Col>
          <h1 style={{color: '#fff'}}>Our Capabilities</h1>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>Microsoft</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>C# .NET</li>
              <li>VB .NET</li>
              <li>Visual C++</li>
              <li>SQL Server</li>
              <li>WPF / UWP</li>
              <li>.NET Core</li>
            </ul>
          </Col>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>Oracle</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>Java</li>
              <li>JavaFX</li>
              <li>Swing</li>
              <li>Database</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>Javascript</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>Node.js</li>
              <li>Electron</li>
              <li>MongoDB</li>
            </ul>
          </Col>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>Other</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>Qt</li>
              <li>PostgreSQL</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="what-we-do" />
      </div>
  </Layout>
    )
  }
}
  